import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "HomeDark",
    component: () => import("../views/HomeDark.vue"),
    meta: {
      title: "Frank Gerards - Webdeveloper VueJs/Fullstack",
    },
  },
  {
    path: "/home-light",
    name: "HomeLight",
    component: () => import("../views/HomeLight.vue"),
    meta: {
      title: "Frank Gerards - Webdeveloper VueJs/Fullstack",
    },
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("../views/PageNotFound.vue"),
    meta: {
      title: "Not Found || Frank Gerards - Webdeveloper VueJs/Fullstack",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
  window.scrollTo(0, 0);
});

export default router;
