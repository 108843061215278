<template>
  <RouterView/>
</template>

<script setup>
import {onMounted} from 'vue';
import {useCookiebot} from '@ambitiondev/vue-cookiebot';

const {consentBanner} = useCookiebot();

onMounted(() => {
    consentBanner();
});

</script>
