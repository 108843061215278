import "bootstrap/dist/css/bootstrap.min.css";
import {createApp} from "vue";
import App from "./App.vue";
import {cookieBot} from '@ambitiondev/vue-cookiebot';
import router from "./router";
import "./assets/scss/main.scss";

const app = createApp(App)
    .use(cookieBot,
        {
            cookieBotId: '8b73cb8d-3a5f-48a2-bbbb-d91a9dc99e0c',
            blockingMode: 'auto',
            culture: 'de',
        }
    );

app.use(router);

app.mount("#app");

import "bootstrap/dist/js/bootstrap";
